import React from "react"
import { Navigate } from "react-router-dom"
import Header from "../../components/Header"
import { getItem } from "../../helpers"
import { useSelector } from "react-redux"

const CmsLayout = props => {
    const isLoggedIn = getItem("isUserLoggedIn")

    const { userInfo, userRoles } = useSelector(store => store.auth)

    return isLoggedIn ? (
        <>
            <Header {...{ userInfo, userRoles }} />
            {props.children}
        </>
    ) : (
        <Navigate to="/auth" replace />
    )
}

export default CmsLayout
