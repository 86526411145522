import React, { lazy, Suspense, useEffect } from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

import AppLoader from "../components/AppLoader"
import InternalError from "../components/InternalError"
import LandingLayout from "./landing/LandingLayout"
import CmsLayout from "./cms/CmsLayout"
import { init as initApm } from "@elastic/apm-rum"
import { useDispatch } from "react-redux"
import { setAuthData } from "./landing/authSlice"
import { getItem, getDecryptedItem, generateUniqueId } from "../helpers"
import Toaster from "../components/Toaster"

const Login = lazy(() => import("./landing/login/Login"))
const Verify = lazy(() => import("./landing/verify/Verify"))
const VerifyPassword = lazy(() => import("./landing/verifyPass/VerifyPass"))
const ForgotPassword = lazy(() => import("./landing/forgot-password/ForgotPassword"))
const ResetPassword = lazy(() => import("./landing/reset-password/ResetPassword"))
const Dashboard = lazy(() => import("./cms/dashboard/Dashboard"))
const ChargerManagement = lazy(() => import("./cms/charger-management/ChargerManagement"))
const CompanyManagement = lazy(() => import("./cms/company-management/CompanyManagement"))
const UserManagement = lazy(() => import("./cms/user-management/UserManagement"))
const Billing = lazy(() => import("./cms/billing/Billing"))
const Complaints = lazy(() => import("./cms/complaints/Complaints"))
const Coupon = lazy(() => import("./cms/coupon/Coupon"))
const Profile = lazy(() => import("./cms/ProfilePage/ProfilePage"))
const Reports = lazy(() => import("./cms/reports/Reports"))

const Application = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        if (!localStorage?.APP_VERSION) {
            localStorage.clear()
            localStorage.setItem("APP_VERSION", process.env.REACT_APP_VERSION)
        } else if (localStorage.APP_VERSION != process.env.REACT_APP_VERSION) {
            localStorage.clear()
            window.location.href = "/auth"
            localStorage.setItem("APP_VERSION", process.env.REACT_APP_VERSION)
        } else if (getItem("isUserLoggedIn")) {
            const payload = {
                isLoggedIn: true,
                userInfo: JSON.parse(getDecryptedItem("userInfo") ? getDecryptedItem("userInfo") : "{}"),
                userRoles: JSON.parse(getDecryptedItem("userRoles") ? getDecryptedItem("userRoles") : "{}"),
            }
            dispatch(setAuthData(payload))
        }
    }, [])

    initApm({
        // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
        serviceName: "csms-ui",
        // Set custom APM Server URL (default: http://localhost:8200)
        serverUrl: process.env.REACT_APP_APM_KEY,
        // Set the service version (required for source map feature)
        serviceVersion: "",
        // Set the service environment
        environment: process.env.REACT_APP_APM !== "local" ? process.env.REACT_APP_APM : "local",
        active: process.env.REACT_APP_APM === "local" ? false : true,
    })

    return (
        <>
            <Toaster vertical="top" horizontal="right" autoHideDuration={3000} />
            <Router>
                <Suspense fallback={<AppLoader />}>
                    <Routes>
                        <Route
                            path="/auth/*"
                            element={
                                <LandingLayout>
                                    <Login />
                                </LandingLayout>
                            }
                        />

                        <Route
                            path="/auth/verify"
                            element={
                                <LandingLayout>
                                    <Verify />
                                </LandingLayout>
                            }
                        />

                        <Route
                            path="/auth/verify_password"
                            element={
                                <LandingLayout>
                                    <VerifyPassword />
                                </LandingLayout>
                            }
                        />

                        <Route
                            path="/auth/forgot-password"
                            element={
                                <LandingLayout>
                                    <ForgotPassword />
                                </LandingLayout>
                            }
                        />

                        <Route
                            path="/auth/set-password"
                            element={
                                <LandingLayout>
                                    <ResetPassword />
                                </LandingLayout>
                            }
                        />

                        <Route
                            path="/auth/reset-password"
                            element={
                                <LandingLayout>
                                    <ResetPassword />
                                </LandingLayout>
                            }
                        />

                        {/* CMS Routes */}

                        {["/", "/dashboard/:component"].map(path => (
                            <Route
                                path={path}
                                element={
                                    <CmsLayout>
                                        <Dashboard />
                                    </CmsLayout>
                                }
                                key={generateUniqueId()}
                            />
                        ))}

                        {["/charger-management/:component", "charger-management"].map(path => (
                            <Route
                                path={path}
                                element={
                                    <CmsLayout>
                                        <ChargerManagement />
                                    </CmsLayout>
                                }
                                key={generateUniqueId()}
                            />
                        ))}

                        {["/company-management/:component", "/company-management"].map(path => (
                            <Route
                                path={path}
                                element={
                                    <CmsLayout>
                                        <CompanyManagement />
                                    </CmsLayout>
                                }
                                key={generateUniqueId()}
                            />
                        ))}

                        {["/user-management/:component", "/user-management"].map(path => (
                            <Route
                                path={path}
                                element={
                                    <CmsLayout>
                                        <UserManagement />
                                    </CmsLayout>
                                }
                                key={generateUniqueId()}
                            />
                        ))}

                        {["/billing/:component", "/billing"].map(path => (
                            <Route
                                path={path}
                                element={
                                    <CmsLayout>
                                        <Billing />
                                    </CmsLayout>
                                }
                                key={generateUniqueId()}
                            />
                        ))}

                        {["/complaints/:component", "/complaints"].map(path => (
                            <Route
                                path={path}
                                element={
                                    <CmsLayout>
                                        <Complaints />
                                    </CmsLayout>
                                }
                                key={generateUniqueId()}
                            />
                        ))}

                        {["/coupon/:component", "/coupon"].map(path => (
                            <Route
                                path={path}
                                element={
                                    <CmsLayout>
                                        <Coupon />
                                    </CmsLayout>
                                }
                                key={generateUniqueId()}
                            />
                        ))}

                        {["/p", "/profile/:component", "/profile"].map(path => (
                            <Route
                                path={path}
                                element={
                                    <CmsLayout>
                                        <Profile />
                                    </CmsLayout>
                                }
                                key={generateUniqueId()}
                            />
                        ))}
                        {["/reports/:component", "/reports"].map(path => (
                            <Route
                                path={path}
                                element={
                                    <CmsLayout>
                                        <Reports />
                                    </CmsLayout>
                                }
                                key={generateUniqueId()}
                            />
                        ))}

                        <Route path="/internal_error" element={<InternalError />} />
                    </Routes>
                </Suspense>
            </Router>
        </>
    )
}
export default Application
